@import '../../styles/app.scss';

.project {
  margin-top: 50px;
  &-banner {
    margin-bottom: 60px;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #00000040;
      z-index: 1;
    }
    .carousel-indicators {
      display: none;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      display: none;
      height: 50px;
      width: 50px;
      background-color: #000000ad;
      border-radius: 100%;
      padding: 14px;
      background-origin: content-box;
    }
  }
  margin-bottom: 90px;
}

.project-detail {
  margin-bottom: 10px;
  &-title {
    img {
      margin-right: 20px;
      height: 100px;
      width: auto;
    }
    h1 {
      font-size: $font-xl + 15;
      margin-bottom: 5px;
    }
    p {
      color: #777777;
    }
  }
  .btn-primary-light {
    width: 160px;
    height: 55px;
    img {
      margin-right: 15px;
    }
  }
  &_tabs {
    border: 1px solid #e6eaf1;
    .nav {
      margin-bottom: 0;
      &-item {
        flex-grow: 1;
        margin-right: 2px;
        margin-bottom: 3px;
        &:last-child {
          margin-right: 0;
        }
        @media screen and (max-width: 1366px) {
          width: 20%;
          margin-bottom: 2px;
        }
        @media screen and (max-width: 991px) {
          width: auto;
        }
        @media screen and (max-width: 525px) {
          width: 40%;
        }
        @media screen and (max-width: 360px) {
          width: 100%;
        }
      }
      &-link {
        background-color: $tab-bg;
        border-radius: 0;
        color: $font-color;
        height: 60px;
        width: 100%;
        border: 0;
        text-transform: uppercase;
        &.active {
          background-color: $primary;
          color: $white;
          border: 0;
        }
      }
    }
    .tab-content {
      background-color: $white;
      padding: 50px 40px;
      min-height: calc(661px - 60px);
    }
  }
}

tr,
td,
th {
  padding: 2px;
  border: 1px solid black;
  text-align: center !important;
  &.doc-table {
    border: none !important;
  }
}

.extra ~ .extra::before {
  content: ', ';
}
.project-contact {
  padding: 5px;
  background-color: $white;
  border: 1px solid $border-color;
  #project-map {
    height: 263px;
    .leaflet_map {
      background-color: #fafeff !important;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  &_list {
    padding: 30px 0 0;
    ul {
      padding-left: 5px;
      li {
        margin-bottom: 20px;
        i {
          color: $primary;
          margin-right: 14px;
          font-size: $font-lg;
        }
        p {
          padding-bottom: 15px;
          border-bottom: 1px solid $border-color;
          flex-grow: 1;
        }
      }
    }
    .start-end_date {
      p {
        background-color: #f7f7f7;
        padding: 17px 23px;
        color: #707070;
        margin-bottom: 20px;
        &:first-child {
          margin-bottom: 1px;
        }
      }
    }
  }
}

.bordered {
  border: 1px solid $border-color;
}

.project-info {
  padding: 35px;
  &_block {
    margin-bottom: 30px;

    img {
      display: block;
    }
    strong {
      display: block;
      margin-bottom: -20px;
    }
  }
  &--title,
  h2 {
    font-size: $font-lg;
    font-weight: 500;
    color: #477cea;
    margin-bottom: 22px;
    padding-bottom: 12px;
    border-bottom: 1px solid $border-color;
  }
  h5 {
    font-size: $font-base;
    font-weight: 400;
  }
  p {
    font-size: $font-base;
    line-height: 35px;
  }
  .text-bold {
    font-weight: 500;
  }
  &--detail {
    display: flex;
    margin-bottom: 5px;
    h5 {
      flex-shrink: 0;
      width: 250px;
      line-height: 25px;
      @media screen and (max-width: 767px) {
        width: auto;
      }
    }
    p {
      position: relative;
      line-height: 25px;
      span.divider:last-child {
        display: none;
      }
      &::before {
        content: ':';
        position: absolute;
        left: -10px;
        top: 0;
      }
      @media screen and (max-width: 767px) {
        padding-left: 10px;
        &::before {
          left: 0;
        }
      }
    }
    .badge {
      font-size: $font-sm;
      font-weight: 400;
      padding: 10px 15px;
      &.pending {
        color: #faaf40;
        background-color: #faaf401a;
        padding: 8px 13px;
      }
      &.completed {
        color: #40cd6d;
        background-color: #e9f9ef;
      }
      &.ongoing {
        color: #dc3545;
        background-color: #dc35452e;
      }

      &.hold {
        color: #625e5e;
        background-color: #f7eeeed4;
      }
      &.canceled {
        color: #dc3545;
        background-color: #dc35452e;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &--list {
    ul {
      padding-left: 23px;
    }
    li {
      margin-bottom: 15px;
      &::marker {
        font-size: 21px;
        color: #6d6e70;
      }
    }
  }
  &--table {
    table {
      width: 100%;
      padding-left: 10px;
      vertical-align: top;

      td {
        font-size: $font-lg;
        padding: 10px;
        width: 33.33%;
        vertical-align: top;
        border: none !important;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
      }

      tr {
        border: none !important;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    margin-bottom: 30px;
  }
}

.image-carousel {
  padding: 5px;
  margin-bottom: 30px;
  h5 {
    font-size: $font-lg;
    font-weight: 500;
    color: #477cea;
    margin-bottom: 20px;
  }
  .carousel {
    &-item {
      height: 245px;
      @media screen and (max-width: 1366px) {
        height: 200px;
      }
      @media screen and (max-width: 1199px) {
        height: 400px;
      }
      img {
        width: 100%;
        height: 350px;
        object-fit: cover;
      }
    }
    &-indicators {
      display: none;
    }
    .btn {
      width: 100%;
      height: 100%;
      padding: 0;
      border: 0;
      &:hover {
        background-color: transparent;
        border: 0;
      }
    }
  }
}
.carousel-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.photo-modal {
  .modal {
    &-dialog {
      max-width: 1000px;
      @media screen and (max-width: 1080px) {
        max-width: 80% !important;
        margin: 0 auto;
      }
    }
    &-content {
      background-color: transparent;
      border: 0;
    }
    &-header {
      border: 0;
    }
    &-body {
      padding: 0;
      img {
        width: 100%;
      }
    }
  }
  .btn-close {
    background-color: #fff;
    opacity: 1;
    border-radius: 100%;
    position: absolute;
    right: 0;
    top: 20px;
    right: -10px;
    z-index: 9;
    padding: 15px;
  }
  .controllers {
    .control {
      position: absolute;
      top: 50%;
      background-color: #636565;
      padding: 11px 17px;
      border-radius: 100%;
      &.control-left {
        left: -70px;
        @media screen and (max-width: 767px) {
          left: 0;
        }
      }
      &.control-right {
        right: -70px;
        @media screen and (max-width: 767px) {
          right: 0;
        }
      }
      @media screen and (max-width: 767px) {
        bottom: -65px;
        top: auto;
      }
    }
  }
}

.credit-link {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  font-size: $font-sm;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9;
}
