@import '../../styles/app.scss';

.aboutUs-banner {
    position: relative;
    margin-bottom: 44px;
    img {
        width: 100%;
    }
    &_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        h2 {
            font-size: $font-xl + 10;
            font-weight: 400;
            margin-bottom: 7px;
        }
        .breadcrumb {
            &-item {
                font-size: $font-xl;
                color: $black;
                a {
                    color: $black;
                }
            }
        }
    }
}

.aboutUs-content {
    margin-bottom: 100px;
}

.about-wrap {
    .btn {
        height: 65px !important;
        font-size: $font-sm;
        width: 198px !important;
        
    }
    i {
        margin-left: 10px;      
    }
    .title {
        font-size: 27px;
        margin-bottom: 27px;
        color: $black;
        font-weight: 400;
    }
    p.description {
        line-height: 33px;
        font-size: $font-base;
        color: #707070;
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.intro {
    margin-bottom: 100px;
}

.about-editors {
    &_card {
       margin-top: 70px;
       margin-bottom: 64px; 
    }
    .card {
        padding: 40px;
        border: 0;
        border-radius: 5px;
        &-img {
            height: 218px;
            width: 218px;
            margin: 0 auto;
            overflow: hidden;
            border-radius: 100%;
            margin-bottom: 30px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.3s all ease-in-out;
            }
        }
        &:hover {
            .card-img {
                img {
                    transform: scale(1.2);
                    transition: 0.3s all ease-in-out;
                }
            }
        }
        
        &-body {
            padding: 0;
            .name {
                font-size: $font-lg;
                font-weight: 400;
                color: $black;
                margin-bottom: 15px;
            }
            p {
                color: #707070;
                font-size: $font-sm;
                margin-bottom: 30px;
            }
            a {
                color: $primary;
                font-size: 13px;
                font-weight: 400;
            }
        }
    }
    .btn-outlined {
        @include primary-outlined;
    }
}

.intro-img {
    width: 525px;
    height: auto;
    float: left;
    margin-right: 30px;
    img {
        width: 100%;
        object-fit: cover;
    }
    @media screen and (max-width: 1400px) {
        width: 600px;
        height: 290px;
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        margin-right: 0;
        height: auto;
    }
}