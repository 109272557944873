@import '../../styles/app.scss';

.launch-btn {
    position: absolute;
    top: 50%;
    background-color: #fff !important;
    color: $font-color !important;
    border-radius: 0 !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    box-shadow: 0 3px 12px #0000001c;
    height: 57px;
    width: 27px;
    padding: 0 !important;
    animation: sideslideback 0.34s forwards;
    z-index: 1000;
    &:hover {
        background: #fff !important;
        border: 0 !important;
    }
    &.btn-opened {
        left: 399px;
        animation: sideslide 0.34s forwards;
        @media screen and (max-width: 1366px) {
            animation: sideslide1366 0.34s forwards;
        }
    }
    &:focus,
    &:active,
    &:active:focus {
        box-shadow: none !important;
    }
}

@keyframes sideslide {
    0% { left: 0}
    100% { left: 399px; }
}

@keyframes sideslideback {
    0% { left: 399px}
    100% { left: 0; }
}

@keyframes sideslide1366 {
    0% { left: 0}
    100% { left: 350px; }
}

@keyframes sideslideback1366 {
    0% { left: 350px}
    100% { left: 0; }
}

.project-offcanvas {
    background-color: $abstract-bg !important;
    &.offcanvas {
        height: calc(100vh - 118px);
        top: 118px !important;
        border: 0 !important;
        position: absolute;
        @include box-shadow;
        @media screen and (max-width: 1366px) {
            height: calc(100vh - 115px);
            top: 115px !important;
        }
    }
    .offcanvas {
        &-header {
            display: block;
            padding: 21px 22px 0px !important;
            &_detail {
                margin-bottom: 18px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        &-body {
            padding: 0 22px 15px !important;
            // overflow-y: hidden;
            @include scrollbar;
        }
        &-title {
            h5 {
                font-size: $font-xl + 5;
                margin-bottom: 5px;
                @media screen and (max-width: 1366px) {
                    font-size: $font-xl;
                }
            }
            p {
                color: #AEAEAE;
                font-size: $font-sm;
            }
        }
    }
    .sort {
        background-color: $primary;
        display: flex;
        color: $white;
        position: relative;
        border-radius: 5px;
        .form-select {
            background: none;
            color: $white;
            border: 0;
            z-index: 1;
            padding: 6px 10px;
            padding-right: 35px;
            option {
                color: $font-color;
            }
        }
        i {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 0;
        }
        .select_block {
            .btn {
                min-width: 90px;
                border: 0 !important;
                height: auto;
                color: $white !important;
                text-transform: capitalize;
                &:focus,
                &:hover {
                    color: $white;
                }
            }
        }
    }
    @media screen and (max-width: 1366px) {
        width: 350px;
    }
}

.project-list {
    &_search {
        @include box-shadow;
        border-radius: 4px;
        border: 1px solid $border-color;
        margin-bottom: 11px;
        input, span {
            border: 0;
            background-color: $white;
        }
        input {
            padding: 15px 0;
            &:focus {
                box-shadow: none;
            }
            @media screen and (max-width: 1366px) {
                padding: 8px 0;
            }
        }
        span {
            padding: 0 20px;
            color: #AEAEAE;
        }
    }
}

// .project-item-wrap {
//     max-height: 610px;
//     overflow-y: auto;
//     @include scrollbar;
//     @media screen and (max-width: 1636px) {
//         max-height: 448px;
//     }
// }