@mixin box-shadow($x:0px, $y: 3px, $blur: 20px , $bg-color: #00000008) {
    -webkit-box-shadow: $x $y $blur $bg-color;
    -moz-box-shadow: $x $y $blur $bg-color;
    -o-box-shadow: $x $y $blur $bg-color;
    box-shadow: $x $y $blur $bg-color;
}

@mixin ripple($color: $primary) {
    background-position: center;
    transition: background 0.5s;
    &:hover {
        background: $color radial-gradient(circle, transparent 1%, $color 1%) center/15000%;
    }
    &:active {
        background-color: $color;
        background-size: 100%;
        transition: background 0s;
    }
}

@mixin primary-btn($radius:5px) {
    background-color: $primary;
    border: 1px solid transparent;
    color: #fff;
    border-radius: $radius;
    &:hover {
        background-color: transparent;
        color: $primary;
        border: 1px solid $primary;
    }
    &:active,
    &:focus {
        background-color: $primary;
        border: 0;
        box-shadow: none;
    }
    &:focus:hover {
        background-color: transparent;
        color: $primary;
        border: 1px solid $primary;
    }
}

@mixin primary-light {
    background-color: $primary-light;
    color: $primary;

    &:focus {
        background-color: $primary-light;
        color: $primary;
        border: 1px solid $primary;
    }
}

@mixin primary-outlined {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;

    &:hover {
        background-color: $primary;
        color: $white;
        border: 1px solid $primary;
    }
}

@mixin btn-info {
    background-color: #477CEA1A;
    color: $abstract;
    border-radius: 4px;
    border: 1px solid transparent;
    &:hover,
    &:focus:hover {
        color: $abstract;
        background-color: transparent;
        border: 1px solid $abstract;
    }
    &:focus,
    &:active {
        background-color: #477CEA1A;
        color: $abstract;
        border: 1px solid transparent;
    }
    &:active:focus {
        box-shadow: none;
    }
}

@mixin btn-group($justify:space-between, $align: center) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin scrollbar {
    
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        background:#fff; 
        border-radius: 6px;
        width: 8px;
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b9b9b9; 
        border-radius: 6px;
        width: 8px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8; 
      }
}