$header-bg: #6D6E70;
$footer-bg: #595959;
$abstract-bg: #F5F5F5;

// fonts
$font-color: #1D1D1D;
$font-sm: 14px;
$font-base: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-family: 'Rubik', sans-serif;


// Buttons
$primary: #FAAF40;
$primary-light: #FAAF4026;
$secondary: #6D6E7024;
$abstract: #477CEA;

$white: #fff;
$black: #000000;

$tab-bg: #E6E9EC;
$border-color: #E6EAF1;

//Icons
$icon-green: #72C838;
$icon-pink: #f34793;
$icon-light-green: #41C0BD;
$icon-blue: #5883F6;

