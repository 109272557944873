@import '../../../styles/app.scss';

.project-list_item {
  @include box-shadow(0, 7px, 6px);
  background-color: $white;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;

  &--image {
    margin-right: 10px;
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 47px;
    .project-img {
      height: 47px;
      width: 50px;
      border-radius: 12px;
      overflow: hidden;
      &-right {
        width: 100%;
        height: 135px;
        object-fit: cover;
      }
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .project-icon {
      position: absolute;
      bottom: -4px;
      right: -4px;
      height: 20px;
      width: 20px;
      // padding: 5px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: contain;
      }
      // &.green {
      //   background-color: $icon-green;
      // }
      // &.pink {
      //   background-color: $icon-pink;
      // }
      // &.blue {
      //   background-color: $icon-blue;
      // }
    }
    &--right {

      .project-img {
        width: 100%;
        height: 100%;
        &-right {
          align-items: center;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &--info {
    h3 {
      font-size: $font-base;
      margin-bottom: 3px;
      font-weight: 400;
      @media screen and (max-width: 1366px) {
        font-size: $font-sm;
      }
    }
    p {
      font-size: $font-sm;
      color: #6d6e6e;
      @media screen and (max-width: 1366px) {
        font-size: $font-sm - 2;
      }
    }
    // &-content {
    //   margin-top: 75px;
    // }
  }

  &.active {
    background-color: #6d6e70;
    .project-list_item--info {
      h3,
      p {
        color: $white;
      }
    }
  }
  @media screen and (max-width: 1366px) {
    padding: 10px;
  }
}

.profile-sidemenu {
  background-color: $white !important;
  color: $black;
  &.offcanvas {
    height: calc(100vh - 118px);
    top: 118px !important;
    border: 0 !important;
    @include box-shadow;
    @media screen and (max-width: 1366px) {
      height: calc(100vh - 115px);
      top: 115px !important;
    }
  }
  .offcanvas {
    &-header {
      border-bottom: 1px solid $border-color;
      padding: 21px 22px 10px !important;
    }
    &-title {
      h5 {
        font-size: $font-xl + 5;
        @media screen and (max-width: 1366px) {
          font-size: $font-xl;
        }
      }
    }
    &-body {
      padding: 15px 22px !important;
      max-height: 730px;
      overflow-y: auto;
      @include scrollbar;
      .project-list_item {
        padding: 0;
        box-shadow: none;
      }
      .button-group {
        .btn {
          min-width: 108px;
          @media screen and (max-width: 1366px) {
            min-width: 80px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    width: 350px;
  }
}

.profile-info {
  margin-bottom: 20px;
  &:first-child {
    .profile-info_head {
      font-size: $font-base;
    }
  }
  &_head {
    font-size: $font-lg;
    font-weight: 400;
    margin-bottom: 15px;
  }
  &_body {
    .description {
      color: #6d6e6e;
    }
  }
  .basic-info {
    font-size: $font-sm;
    padding-left: 12px;
    position: relative;
    margin-bottom: 15px;
    &_head {
      color: $abstract;
      font-weight: 400;
      margin-bottom: 3px;
    }
    p {
      color: $black;
      width: 100%;
      line-break: anywhere;
    }
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 2px;
      top: 0;
      left: 0;
      background-color: $abstract;
      border-radius: 6px;
    }
  }
}
