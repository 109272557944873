@import 'leaflet/dist/leaflet.css';
#map {
  height: calc(100vh - 118px);
  .leaflet_map {
    background-color: #fafeff !important;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  // @media screen and (max-width: 1366px) {
  //   height: calc(100vh - 115px);
  // }
}