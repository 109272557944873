.page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &-not-found {
        text-align: center;
        padding: 10px;
        h1 {
            font-size: 284px;
            color: #6D6E70;
            font-weight: 900;
            span {
                color: #FAAF40;
            }
            @media screen and (max-width: 1366px) {
                font-size: 184px;
            }
            @media screen and (max-width: 767px) {
                font-size: 100px;
            }
            @media screen and (max-width: 525px) {
                font-size: 50px;
            }
        }
        p {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.9px;
            color: #828AAD;
            margin-bottom: 60px;
            @media screen and (max-width: 1366px) {
                margin-bottom: 40px;
            }
            @media screen and (max-width: 525px) {
                font-size: 14px;
            }
        }
        .btn {
            height: 65px;
            width: 270px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            i {
                margin-left: 10px;
            }
            @media screen and (max-width: 525px) {
                height: 45px;
                width: 180px;
                font-size: 12px;
            }
        }
    }
}
