@import '../../styles/app.scss';

.map-toggle{
    position: absolute;
    bottom: 25px;
    left: 25px;
    animation: mapslideback 0.34s forwards;
    z-index: 3;
    align-items: flex-end;
    .show-btn {
        background-color: transparent;
        padding: 0;
        border: 0;
        height: 80px;
        width: 80px;
        position: relative;
        border: 1px solid #000000AB;
        overflow: hidden;
        &:hover,
        &:focus,
        &:active {
            border: 1px solid #000000AB;
            background-color: transparent;
            box-shadow: none;
            color: $white;
        }
        &:focus:hover {
            border: 1px solid #000000AB;
            color: $white;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 7px;
        background-image: linear-gradient(to top, #000000a6, #c3c3c329);
    }
    .nav {
        background-color: $white;
        margin-left: 20px;
        padding: 8px 10px 2px;
        border-radius: 5px;
        @include box-shadow(0, 3px, 6px, #0000000D);
        &-item {
            padding: 0;
            margin-right: 10px;
            img {
                height: 45px;
                width: 45px;
                margin-bottom: 2px;
                border-radius: 5px;
            }
            p {
                color: #6D6E70;
                font-size: 10px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &-link {
            padding: 0;
            text-align: center;
            &.active {
                background-color: transparent;
                img {
                    border: 1px solid #0000007D;
                    @include box-shadow(0px, 3px, 6px, #0000000D);
                }
                p {
                    color: #6D6E70;
                }
            }
        }
    }
    &.toggled {
        left: 420px;
        animation: mapslide 0.34s forwards;
        @media screen and (max-width: 1366px) {
            animation: mapslide1366 0.34s forwards;
        }
    }
}


@keyframes mapslide {
    0% { left: 0}
    100% { left: 420px; }
}

@keyframes mapslideback {
    0% { left: 420px}
    100% { left: 25px; }
}

@keyframes mapslide1366 {
    0% { left: 0}
    100% { left: 380px; }
}

@keyframes mapslideback1366 {
    0% { left: 380px}
    100% { left: 25px; }
}