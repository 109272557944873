@import "../../styles/app.scss";

.form-block {
  padding: 0 0 10px 0;
  display: flex;
  &_item {
    position: relative;
    label {
      font-size: $font-sm - 2;
      color: #b3bdc8;
      margin-bottom: 6px;
    }
    p,
    input {
      font-size: $font-sm;
    }
    .input-group {
      border: 1px solid $primary;
      border-radius: 5px;
      padding: 2px 20px;
      background-color: $white;
      height: 38px;
      input {
        border: 0;
        padding: 0;
        color: $font-color;
        width: 300px;
        outline: none;
        height: 100%;
        &:focus {
          box-shadow: none;
        }
        @media screen and (max-width: 1400px) {
          width: 200px;
        }
        @media screen and (max-width: 1199px) {
          width: 155px;
        }

      }
      &-text {
        background-color: transparent;
        border: 0;
        padding-right: 0;
        i {
          color: #b3bdc8;
        }
      }
      @media screen and (max-width: 1199px) {
        display: flex;
        flex-wrap: nowrap;
      }
    }
    #react-autowhatever-1 {
      position: absolute;
      left: 0;
      top: calc(100% + 10px);
      width: 100%;
      padding: 0 15px;
      z-index: 9999;
      background: #fff;
  }

  #react-autowhatever-1 ul {
      list-style: none;
      padding: 0;
  }

  #react-autowhatever-1 li {
      font-size:16px;
      cursor: pointer;
      margin-top: 10px;
      transition: 0.2s ease-in-out 0s;
  }

  #react-autowhatever-1 li:hover {
      color: #faaf40
  }

    select {
      border: 1px solid $border-color;
      border-radius: 5px;
      height: 48px;
      font-size: $font-sm;
      width: 200px;
      &:disabled {
        border: 0;
      }
    }
    .select_block {
      .btn {
        border: 1px solid $primary !important;
        border-radius: 5px;
        height: 38px;
        margin-left: 10px;
        font-size: $font-sm;
        width: 250px;
        background-color: $white !important;
        color: $black !important;
        text-align: left;
        span {
          white-space: nowrap;
          /* max-width: 190px; */
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 5px;
          color: in;
        }
        &.dropdown-toggle {
          &:hover,
          &:focus:hover,
          &:focus {
            background-color: transparent;
            color: $black;
            border: 1px solid $border-color;
          }
          &:focus {
            box-shadow: none;
          }
          &:after {
            content: "\f107";
            border: 0;
            font-family: "Font Awesome 6 Free";
            position: absolute;
            top: 45%;
            right: 6%;
          }

        }
        &:disabled {
          border: 0;
        }
        @media screen and (max-width: 1400px) {
          width: 150px;
        }
        @media screen and (max-width: 1199px) {
          width: 130px;
        }
      }
      .dropdown-menu {
        border: 0;
        @include box-shadow;
        width: 100%;
        .dropdown-item {
          color: #6D6E70;
          border-bottom: 1px solid #E8E8E85E;
          padding: 0.5rem 1rem;
          &:last-child {
            border-bottom: 0;
          }
        }
      }

    }
    .reset-btn {
      background-color: transparent;
      color: $primary;
      border: 0 !important;
      box-shadow: none !important;
      &:hover {
        color: $primary;
      }
      &:active,
      &:focus {
        background-color: transparent;
        color: $primary;
      }
      i {
        color: $primary;
        transform: rotate(-90deg);
        margin-right: 5px;
        font-size: $font-base;
      }
      @media screen and (max-width: 1199px) {
       padding: 0;
      }
    }
    .form-check {
      display: flex;
      align-items: center;
      &-input {
        width: 22px;
        height: 22px;
        &:checked {
          background-color: $primary;
          border-color: $primary;
        }
        &:focus {
          border-color: #faaf40;
          box-shadow: 0 0 0 0.25rem #faaf4047;
        }
      }
      &-label {
        margin-bottom: 0;
        margin-left: 10px;
        color: $font-color;
        font-size: $font-sm;
      }
    }
    .btn-lg {
      border: 1px solid $primary;
      width: 180px !important;
      height: 38px !important;
      &:hover {
        background-color: $white;
      }
      &:focus {
        border: 1px solid $white;
        background-color: $primary;
        color: $white;
        box-shadow: none;
      }
      &:focus:hover {
        border: 1px solid $white;
        background-color: $white;
        color: $primary;
      }
      @media screen and (max-width: 1400px) {
        width: 150px !important;
      }
      @media screen and (max-width: 1199px) {
        width: 120px !important;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    flex-wrap: nowrap !important;
  }
}

.nav-filter {
  position: relative;
  z-index: 1500;
  background-color: #fff;
  &_title {
    font-size: $font-lg;
    color: #707070;
    font-weight: 600;
    white-space: nowrap;
    @media screen and (max-width: 1199px) {
      font-size: $font-base;
    }
  }
  &_block {
    @media screen and (max-width: 991px) {
      flex-wrap: nowrap;
      @include scrollbar;
    }
 }

}

