@import '../../styles/app';

.header_home .__header{
  border: none !important;
}

.__header {
  background-color: $white;
  position: relative;
  z-index:1500;

  // color: $header-bg;
  @include box-shadow;
  border-bottom: 1px solid $border-color;
  .container {
    position: relative;
  }
  .nav {
    &-link {
      color: $black !important;
      font-size: 16px;
      margin-left: 60px;
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      margin-bottom: 1px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        color: $primary !important;
      }
      &.active {
        color: $primary !important;
        border-bottom: 2px solid $primary;
        font-weight: 500;
      }
      @media screen and (max-width: 1366px) {
        padding: 11px 0.5rem !important;
      }
      @media screen and (max-width: 991px) {
        margin-left: 0;
      }
    }
  }
  .navbar {
    padding: 0;
    &-brand {
      color: $black !important;
      font-size: $font-xl;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
    &-toggler {
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
    &-nav {
      @media screen and (max-width: 991px) {
        flex-direction: row;
        justify-content: space-between;

      }
      @media screen and (max-width: 630px) {
        flex-direction: column;
        justify-content: flex-start;

      }
    }
    @media screen and (max-width: 1366px) {
      padding: 0;
    }
    .navbar-collapse {
      @media screen and (max-width: 991px) {
        position: absolute;
        width: 100%;
        background: white;
        top: 41px;
        z-index: 9;
        border: 1px solid #e8e8e8;
        border-top: 0;
      }
    }
  }
}
