@import '../../styles/app.scss';

.footer {
  background-color: $footer-bg;
  text-align: center;
  color: $white;
  hr {
    margin: 0;
  }
}

.footer-top {
  padding: 40px 0;
}

.footer-bottom {
  padding: 22px 0;
  letter-spacing:1px;
  a{
    color: #faaf40;
  }
}

.footer_block {
  &-title {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 18px;
    color: #faaf40;
  }
  &-item {
    p {
      color: #fff;
      font-size: $font-sm;
      line-height: 25px;
  }
    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      li {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    a {
      font-size: $font-sm;
      color: #E1DFDF;
      &:hover {
        color: $primary;
      }
    }
  }
  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    a {
      color: #faaf40;
      margin-right: 10px;
      width: 31px;
      height: 32px;
      border-radius: 100%;
      background: #413f3f;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s ease-in-out 0s;
      font-size: 13px;
      &:hover {
        color: $white;
      }
      &:last-child{
        margin-right: 0px;
 }
    
    }
      
    li {
      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
    .input-group {
      input {
        height: 60px;
        font-size: $font-sm;
        color: $font-color;
        min-width: 200px;
        border-radius: 0;
        &::placeholder {
          color: rgba(112, 112, 112, .6);
        }
      }
      .btn {
        border-radius: 0;
      }
  
    }
  }
}
.footer-btm-sec {
  background: #f5f5f5;
  color: #b2b7b8;
  font-size: 12px;
}
.home-footer {
  position: fixed;
   width: 100%;
   left: 0;
   bottom: 0;
   z-index: 1200;
   transform: translateY(100%);
   transition: 0.3s ease-in-out 0s;
}
.home-footer .footer {
  max-height: calc(100vh - 167px);
  overflow: auto;
}
.home-footer-btn {
  transform: translate3d(0, -27px, 0) !important;
  position: fixed;
  left: 50%;
  background-color: #fff!important;
  border-radius: 0!important;
  border-top-right-radius: 12px!important;
  border-top-left-radius: 12px!important;
  box-shadow: 0 3px 12px #0000001c;
  color: #1d1d1d!important;
  height: 27px;
  padding: 0!important;
  position: fixed;
  width: 58px;
  border: none;
  margin-left: -29px;
}
.home-footer-btn i {
  transition: 0.3s ease-in-out 0s;
}
.home-footer.show{
  transform: translateY(0%);
}
.home-footer.show .home-footer-btn i {
  transform: rotate(180deg);
}
