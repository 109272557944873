@import "../../styles/app.scss";
.contact-form {
  padding: 120px 0 80px;
  @media screen and (max-width: 991px) {
      padding: 70px 0 50px;
  }
  h1 {
      text-align: center;
      margin-bottom: 48px;
      font-size: 40px;
      @media screen and (max-width: 991px) {
          font-size: 30px;
          margin-bottom: 30px;
      }
  }
  &_feilds {
      max-width: 960px;
      margin: 0 auto;
  }
  input, textarea {
      border: 1px solid $border-color;
      border-radius: 4px;
      padding: 20px;
      font-size: $font-sm;
      &::placeholder {
          color: #0D263B;
      }
  }
  input {
      height: 60px;
      @media screen and (max-width: 991px) {
          height: 48px;
      }
  }
  textarea {
      width: 100%;
      resize: none;
      height: 232px;
  }
  .btn {
      font-size: $font-base;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px auto 0;
      width: 246px !important;
      height: 60px !important;
      @media screen and (max-width: 991px) {
          width: 150px !important;
          height: 48px !important;
      }
  }
}