@import "../../styles/app.scss";

.project-database {
  &_header {
    background-color: #fff;
    padding: 28px 0;
  }
  .filter-section {
    padding: 10px 0;
    @include box-shadow(0, 3px, 20px);
    .dropdown-toggle {
      min-width: 150px;
      margin-bottom: 10px;
    }
    .btn {
      height: 48px;
      width: 143px;
    }
    .w-lg {
      width: 230px;
    }
    .col {
      @media screen and (max-width: 1666px) {
        flex: 0;
      }
    }
    .searchWrapper {
      min-height: 48px;
      display: flex;
      align-items: center;
      padding: 0.375rem 0.75rem;
      white-space: nowrap;
      border: 1px solid $border-color;
      input {
        margin-top: 0;
      }
    }
    .highlightOption {
      background: $primary;
    }
    .multiSelectContainer li:hover {
      background: $primary;
    }
    .dropdown-toggle {
      width: 100%;
      span {
        white-space: nowrap;
        max-width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 5px;
      }
    }
    .dropdown-menu {
      width: 300px;
    }
    .dropdown-item {
      width: 300px;
      word-break: break-all;
      display: block;
      white-space: normal;

    }
    input {
      height: 48px;
      width: 185px;
      font-size: 14px;
      background: transparent;
      border: 1px solid $border-color;
      &:focus {
        box-shadow: none;
      }
    }

    #react-autowhatever-1 {
      position: absolute;
      left: 0;
      top: calc(100% + 10px);
      width: 150%;
      padding: 0 5px;
      z-index: 99;
      background: #fff;
  }
  
  #react-autowhatever-1 ul {
      list-style: none;
      padding: 0;
  }
  
  #react-autowhatever-1 li {
      font-size: 16px;
      cursor: pointer;
      margin-top: 15px;
      transition: 0.2s ease-in-out 0s;
  }
  
  #react-autowhatever-1 li:hover {
      color: #faaf40
  }

    .reset-btn {
      background-color: transparent;
      color: $primary;
      border: 0 !important;
      box-shadow: none !important;
      &:hover {
        color: $primary;
      }
      &:active,
      &:focus {
        background-color: transparent;
        color: $primary;
      }
      &:focus:hover {
        color: $primary;
      }
      i {
        color: $primary;
        transform: rotate(-90deg);
        margin-right: 5px;
        font-size: $font-base;
      }
    }

  }
  &_table {
    margin-top: 60px;
    .table-responsive {
      @include scrollbar;
      @media screen and (min-width: 1536px) {
        overflow-x: visible;
      }
    }
    table {
      vertical-align: middle;
      border: 0;
      border-collapse: separate;
      border-spacing: 0 1rem;
      font-size: $font-base;
      & > :not(:first-child) {
        border: 0;
      }
    }
    thead,
    tbody,
    th,
    td {
      border: 0;
    }
    thead {
      th {
        padding: 15px;
        color: #5d5c5c;
        text-transform: uppercase;
        font-weight: 400;
        white-space: nowrap;
      }
    }
    tbody {
      td {
        background-color: $white;
        padding: 15px;
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
    .project-image {
      height: 45px;
      width: 45px;
      border-radius: 4px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .author {
      display: flex;
      &-img {
        margin-right: 10px;
        height: 45px;
        width: 45px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-info {
        h5,
        p {
          font-size: $font-sm;
          color: #5d5c5c;
        }
        h5 {
          text-transform: uppercase;
          font-weight: 400;
        }
      }
    }
    .pagination {
      margin: 10px 0 40px;
      .page-link {
        background-color: #f1f1f1;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        color: $black;
        font-size: $font-sm - 1;
        &:focus {
          box-shadow: none;
        }
      }
      .page-item {
        margin: 0 10px;
        &.active {
          .page-link {
            background-color: #595959;
            color: $white;
            border: 1px solid #595959;
          }
        }
      }
    }
  }
}

.data-not-found {
  text-align: center;
  margin: 100px 0;
  img {
    margin-bottom: 50px;
  }
  h3 {
    font-variant-position: 43px;
    color: #6D6E70;
    font-weight: 500;
    margin-bottom: 8px;
  }
  p {
    font-size: 16px;
    color: #828AAD;
  }
}
