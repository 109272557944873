@import 'leaflet/dist/leaflet.css';
body {
  margin: 0;
  font-family: $font-family !important;
  font-size: $font-sm !important;
  background-color: #fafafa !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1666px) {
  .container {
    max-width: 1560px;
  }
}

p {
  margin-bottom: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  color: #477CEA;
  &:hover {
    text-decoration: none !important;
  }
}

.bg-primary {
  background-color: $primary !important;
}

.btn-primary {
  @include primary-btn;
}
.btn-primary-light {
  @include primary-light;
}

.btn {
  font-size: $font-sm;
  &.btn-lg {
    height: 48px;
    width: 125px;
  }
}

select,
input {
  &:focus,
  &:active,
  &:active:focus {
    box-shadow: none;
  }
}

select {
  &:focus {
    box-shadow: none !important;
    border: 1px solid #bfbfbf;
    border-color: #bfbfbf !important;
  }
}

.button-group {
  @include btn-group;
}

.btn-info {
  @include btn-info;
}

.select_block {
  .btn {
    border: 1px solid #E6EAF1 !important;
    text-align: left;
    background-color: transparent !important;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: normal;
    color: $font-color !important;
    &:focus {
      box-shadow: none !important;
      color: $font-color;
    }
    &::after {
      display: none;
    }
    &:hover {
      border: 1px solid #E6EAF1 !important;
      color: $font-color;
    }
    &:disabled {
      background-color: #E6EAF161 !important;
      color: $font-color !important;
      opacity: 1;
      border: 0 !important;
    }
  }
  .dropdown-menu {
      border: 0;
      @include box-shadow;
      max-width: 300px;
      min-width: auto;
      max-height: 300px;
      overflow: auto;
      .dropdown-item {
        color: #6D6E70;
        border-bottom: 1px solid #E8E8E85E;
        padding: 0.5rem 1rem;
        width: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;



        &:last-child {
          border-bottom: 0;
        }
        &:active,
        &:focus {
          background-color: #e9ecef !important;
        }
      }
    }
}

.leaflet-div-icon {
  background: none;
  border: 0;
}
.navbar.navbar-light {
  min-height: 70px;
}
.navbar-brand img {
  max-height: 60px;
}