.project-database_table,
.about-wrap,
.contact,.align-items-page {
  min-height: 450px;
}
.project-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.project-wrapper .__header {
  flex-shrink: 0;
}

.project-content {
    flex-grow: 1;
    overflow: auto;
}