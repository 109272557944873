@import "../../styles/app.scss";
.contact-banner {
  max-height: 630px;
  position: relative;
  img {
    height: 630px;
    width: 100%;
    object-fit: cover;
    @media screen and (max-width: 991px) {
      height: 230px;
    }
  }
  &_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    @media screen and (max-width: 525px) {
      top: 50px;
      left: 10px;
      right: 10px;
      transform: none;
    }
    h1 {
      font-size: 50px;
      font-weight: 500;
      margin-bottom: 48px;
      @media screen and (max-width: 991px) {
        font-size: 35px;
        margin-bottom: 20px;
      }
    }
    p {
      font-size: $font-xl + 2;
      @media screen and (max-width: 991px) {
        font-size: $font-base;
      }
    }
  }
  @media screen and (max-width: 991px) {
    height: 230px;
  }
}

.contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000c2;
}